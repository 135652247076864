/**
 * Derives error from axios response object
 * @param {object} error
 * @returns {string} Returns a string error
 */
export const DeriveError = (error) => {
  if (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      const message = error.response.data.message;
      if (message) return message;
      else return error.response.status;
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(error.request);
      return "Request Error";
    } else {
      // Something happened in setting up the request and triggered an Error
      console.log("Error", error.message);
      return error.message;
    }
  }
};
